import React, { useState } from "react";
import UseGetCommon from "../hooks/UseGetCommon";
import List from "../pages/List/List";

const MtupListContainer = () => {
  const [SortType, setSortType] = useState<number | undefined>(
    localStorage.getItem("sort")
      ? Number(localStorage.getItem("sort"))
      : undefined
  );
  const [stateType, setStateType] = useState<number | undefined>(
    localStorage.getItem("state")
      ? Number(localStorage.getItem("state"))
      : undefined
  );
  const [Listdata, PagingUpdate] = UseGetCommon({
    path: `/web/prjt?prjt_type_cd=mtup&prjt_sts_cd=${
      stateType
        ? stateFilter[stateType].type
        : "prjt_sts_rcrt_ing,prjt_sts_end,prjt_sts_ing,prjt_sts_rcrt_end,prjt_sts_rcrt_wait"
    }&prjt_sort_order=${
      SortType ? mtupSort[SortType].type : "new"
    }&row_limit_cnt=9`,
    limit: 6,
  });
  return (
    <List
      type="meetup"
      Listdata={Listdata}
      SortType={SortType}
      stateType={stateType}
      setSortType={setSortType}
      setStateType={setStateType}
      PagingUpdate={PagingUpdate}
      sortlayout={mtupSort}
      key="mtup-list"
    />
  );
};

export default MtupListContainer;

const mtupSort = [
  {
    title: "최신순",
    type: "new",
  },
  {
    title: "인기순",
    type: "hot",
  },
  {
    title: "마감 임박 순",
    type: "old",
  },
  {
    title: "비대면 우선순",
    type: "off",
  },
];

const stateFilter = [
  {
    title: "전체",
    type: undefined,
  },
  {
    title: "오픈예정",
    type: "prjt_sts_rcrt_wait",
  },
  {
    title: "모집중",
    type: "prjt_sts_rcrt_ing,",
  },
  {
    title: "모집마감",
    type: "prjt_sts_end,prjt_sts_ing,prjt_sts_rcrt_end",
  },
];
