import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import "./css/index.css";
function ListFilter({
  list,
  now,
  __update,
  type,
}: {
  list: {
    title: string;
    type: string | undefined;
  }[];
  now: string;
  type: string;

  __update: Dispatch<SetStateAction<number | undefined>>;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [save, setSave] = useState<number | undefined>(undefined);
  const __filterChange = useCallback(() => {
    __update(save);

    setIsOpen(false);
  }, [save, __update]);
  return (
    <div
      className={`drop-down ${isOpen ? "down-active" : ""} ${
        isOpen ? `${type}-down` : ""
      } ${type}-drop`}
      style={
        isOpen
          ? {
              height: `${40 * (list.length + 1)}px`,
            }
          : undefined
      }
    >
      <button
        className="now"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {now}
        <img src="/assets/list/filter-arrow.svg" alt="" />
      </button>
      <div
        className="select-list"
        style={
          isOpen
            ? {
                height: `${40 * list.length}px`,
              }
            : undefined
        }
      >
        {list.map(({ title }, idx) => {
          return (
            <button
              className={`select ${now === title ? "active-select" : ""}`}
              key={idx}
              onClick={() => {
                if (now !== title) {
                  __update(idx);
                } else {
                  __update(undefined);
                }

                setIsOpen(false);
              }}
            >
              {title}
            </button>
          );
        })}
      </div>
      <div className="select-list mobile">
        <img
          src="/assets/list/cancel.svg"
          alt=""
          className="cancel"
          onClick={() => {
            setIsOpen(false);
          }}
        />
        <div className="title">
          {type === "filter"
            ? "관심분야"
            : type === "teampl"
            ? "상태"
            : "정렬 순서"}
          <span>1개 선택</span>
        </div>
        <div className="category-list">
          {list.map(({ title, type }, idx) => {
            return (
              <button
                className={`select ${save === idx ? "active-select" : ""}`}
                key={idx}
                onClick={() => {
                  if (save !== idx) {
                    setSave(idx);
                  } else {
                    setSave(undefined);
                  }
                }}
              >
                {title}
              </button>
            );
          })}
        </div>
        <button className="insert" onClick={__filterChange}>
          필터 적용하기
        </button>
      </div>
      {isOpen ? (
        <div
          className="back"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      ) : undefined}
    </div>
  );
}

export default ListFilter;
