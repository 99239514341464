import React, { useState } from "react";
import UseGetCommon from "../hooks/UseGetCommon";
import { categoryArr, encrypto } from "../lib/lib";
import List from "../pages/List/List";

const ProjectListContainer = () => {
  const [filterType, setFilterType] = useState<number | undefined>(
    localStorage.getItem("filter")
      ? Number(localStorage.getItem("filter"))
      : undefined
  );
  const [SortType, setSortType] = useState<number | undefined>(
    localStorage.getItem("sort")
      ? Number(localStorage.getItem("sort"))
      : undefined
  );
  const [stateType, setStateType] = useState<number | undefined>(
    localStorage.getItem("state")
      ? Number(localStorage.getItem("state"))
      : undefined
  );
  const [Listdata, PagingUpdate] = UseGetCommon({
    path: `/web/prjt?prjt_type_cd=prjt&prjt_sts_cd=${
      stateType
        ? stateFilter[stateType].type
        : "prjt_sts_rcrt_ing,prjt_sts_end,prjt_sts_ing,prjt_sts_rcrt_end,prjt_sts_rcrt_wait"
    }&prjt_sort_order=${SortType ? projectSort[SortType].type : "new"}${
      filterType ? `&prjt_intrst=${categoryArr[filterType].type}` : ""
    }&row_limit_cnt=9`,
    limit: 6,
  });
  return (
    <List
      type="project"
      Listdata={Listdata}
      filterType={filterType}
      SortType={SortType}
      stateType={stateType}
      setFilterType={setFilterType}
      setSortType={setSortType}
      setStateType={setStateType}
      PagingUpdate={PagingUpdate}
      sortlayout={projectSort}
      key="project-list"
    />
  );
};

export default ProjectListContainer;

const projectSort = [
  {
    title: "최신순",
    type: "new",
  },
  {
    title: "인기순",
    type: "pplr",
  },
  {
    title: "마감 임박 순",
    type: "rcrt",
  },
];
const stateFilter = [
  {
    title: "전체",
    type: undefined,
  },
  {
    title: "오픈예정",
    type: "prjt_sts_rcrt_wait",
  },
  {
    title: "모집중",
    type: "prjt_sts_rcrt_ing",
  },
  {
    title: "모집마감",
    type: "prjt_sts_end,prjt_sts_ing,prjt_sts_rcrt_end",
  },
];
